import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26dcb880"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-container" }
const _hoisted_2 = { class: "item-content" }
const _hoisted_3 = { class: "col-12 form-col" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_uom_input = _resolveComponent("b-form-uom-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_button_upload_image = _resolveComponent("button-upload-image")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_orbis_card = _resolveComponent("orbis-card")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.pageTitle,
    "go-back": _ctx.goToItemList,
    full: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, { class: "item-footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_orbis_card, {
            title: _ctx.getTitleCaseTranslation('core.domain.details')
          }, {
            default: _withCtx(() => [
              (!_ctx.state.loading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_text_input, {
                          modelValue: _ctx.state.itemEdit.customerItemNumber,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.itemEdit.customerItemNumber) = $event)),
                          error: _ctx.validationResult.model.customerItemNumber,
                          label: _ctx.getTitleCaseTranslation('core.domain.itemNumber'),
                          cols: "3",
                          class: "form-col",
                          "null-if-empty": ""
                        }, null, 8, ["modelValue", "error", "label"]),
                        _createVNode(_component_text_input, {
                          modelValue: _ctx.state.itemEdit.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.itemEdit.name) = $event)),
                          error: _ctx.validationResult.model.name,
                          label: _ctx.getTitleCaseTranslation('core.domain.name'),
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label"]),
                        _createVNode(_component_text_input, {
                          modelValue: _ctx.state.itemEdit.shortName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.itemEdit.shortName) = $event)),
                          error: _ctx.validationResult.model.shortName,
                          label: _ctx.getTitleCaseTranslation('core.domain.shortName'),
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label"]),
                        _createVNode(_component_select_input, {
                          error: _ctx.validationResult.model.type,
                          label: _ctx.getTitleCaseTranslation('core.domain.itemType'),
                          model: _ctx.state.itemEdit.type?.id,
                          options: _ctx.itemTypes,
                          text: "description",
                          value: "id",
                          cols: "3",
                          class: "form-col",
                          onChange: _cache[3] || (_cache[3] = (type) => (_ctx.state.itemEdit.type = type))
                        }, null, 8, ["error", "label", "model", "options"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_text_input, {
                          modelValue: _ctx.state.itemEdit.floorLocation,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.itemEdit.floorLocation) = $event)),
                          label: _ctx.getTitleCaseTranslation('core.domain.floorLocation'),
                          error: _ctx.validationResult.model.floorLocation,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "label", "error"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_uom_input, {
                          modelValue: _ctx.state.itemEdit.length,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.itemEdit.length) = $event)),
                          error: _ctx.validationResult.model.length,
                          label: _ctx.getTitleCaseTranslation('core.domain.length'),
                          uom: _ctx.useItemLengthUom,
                          required: true,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label", "uom"]),
                        _createVNode(_component_b_form_uom_input, {
                          modelValue: _ctx.state.itemEdit.width,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.itemEdit.width) = $event)),
                          error: _ctx.validationResult.model.width,
                          label: _ctx.getTitleCaseTranslation('core.domain.width'),
                          uom: _ctx.useItemWidthUom,
                          required: true,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label", "uom"]),
                        _createVNode(_component_b_form_uom_input, {
                          modelValue: _ctx.state.itemEdit.height,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.itemEdit.height) = $event)),
                          error: _ctx.validationResult.model.height,
                          label: _ctx.getTitleCaseTranslation('core.domain.height'),
                          uom: _ctx.useItemHeightUom,
                          required: true,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label", "uom"]),
                        _createVNode(_component_checkbox_input, {
                          modelValue: _ctx.state.itemEdit.collapsible,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.itemEdit.collapsible) = $event)),
                          error: _ctx.validationResult.model.collapsible,
                          label: _ctx.getTitleCaseTranslation('core.domain.collapsible'),
                          "inline-right": true,
                          cols: "1",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label"]),
                        _createVNode(_component_b_form_uom_input, {
                          modelValue: _ctx.state.itemEdit.collapsedHeight,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.itemEdit.collapsedHeight) = $event)),
                          disabled: !_ctx.state.itemEdit.collapsible,
                          error: _ctx.validationResult.model.collapsedHeight,
                          label: _ctx.getTitleCaseTranslation('core.domain.collapsedHeight'),
                          uom: _ctx.useItemCollapsedHeightUom,
                          required: true,
                          cols: "2",
                          class: "form-col"
                        }, null, 8, ["modelValue", "disabled", "error", "label", "uom"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_uom_input, {
                          modelValue: _ctx.state.itemEdit.weight,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.itemEdit.weight) = $event)),
                          error: _ctx.validationResult.model.weight,
                          label: _ctx.getTitleCaseTranslation('core.domain.weight'),
                          uom: _ctx.useItemWeightUom,
                          required: true,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label", "uom"]),
                        _createVNode(_component_text_input, {
                          modelValue: _ctx.state.itemEdit.palletQty,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.itemEdit.palletQty) = $event)),
                          error: _ctx.validationResult.model.palletQty,
                          "hide-stepper": "",
                          label: _ctx.getTitleCaseTranslation('core.domain.partnerOrderMultiple') ,
                          required: true,
                          type: "number",
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label"]),
                        _createVNode(_component_b_col, { cols: "3" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_form_input, {
                              modelValue: _ctx.state.itemEdit.unitCost,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.itemEdit.unitCost) = $event)),
                              error: _ctx.validationResult.model.unitCost,
                              label: _ctx.getTranslation('core.domain.unitCostUSD'),
                              type: "number",
                              "append-group-text": "$",
                              "number-options": { allowsDecimal: true },
                              class: "form-col"
                            }, null, 8, ["modelValue", "error", "label"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_checkbox_input, {
                          modelValue: _ctx.state.itemEdit.isUnitLoad,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.itemEdit.isUnitLoad) = $event)),
                          error: _ctx.validationResult.model.isUnitLoad,
                          label: _ctx.getTitleCaseTranslation('core.domain.unitLoad'),
                          disabled: _ctx.state.itemEdit.unitLoadParts.length > 0 || !_ctx.canEditCombinedBuild,
                          "inline-right": true,
                          cols: "3",
                          class: "form-col"
                        }, null, 8, ["modelValue", "error", "label", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, { cols: "3" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_form_input, {
                              modelValue: _ctx.state.itemEdit.stackHeight,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.itemEdit.stackHeight) = $event)),
                              label: _ctx.getTitleCaseTranslation('core.domain.stackHeight'),
                              class: "form-col",
                              type: "number"
                            }, null, 8, ["modelValue", "label"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_b_col, { cols: "3" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_form_input, {
                              modelValue: _ctx.state.itemEdit.fullTrailerLoad,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.itemEdit.fullTrailerLoad) = $event)),
                              label: _ctx.getTitleCaseTranslation('core.domain.fullTrailerLoad'),
                              cols: "3",
                              class: "form-col",
                              type: "number"
                            }, null, 8, ["modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.image')), 1),
                          _createVNode(_component_thumbnail, {
                            size: "lg",
                            "image-url-thumb": _ctx.state.itemEdit.imageUrlThumb,
                            "image-url-full": _ctx.state.itemEdit.imageUrlFull,
                            "encoded-image": _ctx.state.itemEdit.image,
                            "encoded-image-type": _ctx.state.newImageType,
                            "show-placeholder": ""
                          }, null, 8, ["image-url-thumb", "image-url-full", "encoded-image", "encoded-image-type"]),
                          _createVNode(_component_button_upload_image, {
                            style: {"width":"130px","text-align":"center","padding-top":"5px"},
                            "button-text": _ctx.titleCase(_ctx.$t('core.button.uploadImage')),
                            onOnUpload: _ctx.onUploadImage
                          }, null, 8, ["button-text", "onOnUpload"])
                        ])
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createBlock(_component_b_spinner, { key: 1 }))
            ]),
            _: 1
          }, 8, ["title"]),
          (_ctx.state.itemEdit.isUnitLoad)
            ? (_openBlock(), _createBlock(_component_orbis_card, {
                key: 0,
                title: _ctx.getTitleCaseTranslation('core.domain.unitLoad')
              }, {
                "header-right": _withCtx(() => [
                  (_ctx.canEditCombinedBuild)
                    ? (_openBlock(), _createBlock(_component_b_button, {
                        key: 0,
                        variant: "primary",
                        onClick: _ctx.displayAddItemModal
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.add')) + " ", 1),
                          _createVNode(_component_faicon, { icon: "box-open" })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_table, {
                    items: _ctx.combinedTableData,
                    fields: _ctx.combinedTableFields,
                    "empty-state": {
                            title: _ctx.getTranslation('core.common.noCombinedItems'),
                            message: _ctx.getTranslation('core.common.clickAddToStartAddingCombinedItems'),
                            icon: 'object-group',
                            button: {
                                onClick: _ctx.displayAddItemModal,
                                text: _ctx.getTitleCaseTranslation('core.button.add'),
                                disabled: !_ctx.canEditCombinedBuild,
                            },
                        },
                    style: {"min-height":"500px"}
                  }, {
                    "cell(imageUrlThumb)": _withCtx((row) => [
                      _createVNode(_component_thumbnail, {
                        "image-url-thumb": row.item.imageUrlThumb,
                        "image-url-full": row.item.imageUrlFull
                      }, null, 8, ["image-url-thumb", "image-url-full"])
                    ]),
                    "cell(quantity)": _withCtx((row) => [
                      (_ctx.canEditCombinedBuild)
                        ? (_openBlock(), _createBlock(_component_quantity_picker, {
                            key: 0,
                            error: _ctx.validationResult.model.unitLoadParts?.[row.item.id]?.quantity,
                            "model-value": row.item.quantity,
                            onChange: (qty) => _ctx.changeCombinedPartQty(qty, row.item.id)
                          }, null, 8, ["error", "model-value", "onChange"]))
                        : _createCommentVNode("", true),
                      (!_ctx.canEditCombinedBuild)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(row.item.quantity), 1))
                        : _createCommentVNode("", true)
                    ]),
                    "cell(isPrimaryPlanningItem)": _withCtx((row) => [
                      _createVNode(_component_checkbox_input, {
                        disabled: !_ctx.canEditCombinedBuild,
                        "model-value": row.item.isPrimaryPlanningItem,
                        onChange: (newValue) => _ctx.setPrimaryPlanningPartForUnitLoad(row.item.id, newValue)
                      }, null, 8, ["disabled", "model-value", "onChange"])
                    ]),
                    "cell(action)": _withCtx((row) => [
                      (_ctx.canEditCombinedBuild)
                        ? (_openBlock(), _createBlock(_component_b_button, {
                            key: 0,
                            variant: "primary",
                            style: {"float":"right"},
                            onClick: ($event: any) => (_ctx.removePartFromCombinedItem(row.item.id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.remove')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["items", "fields", "empty-state"])
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showAddItemForCombinedContainer,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.showAddItemForCombinedContainer) = $event)),
        centered: "",
        "hide-footer": "",
        title: _ctx.getTitleCaseTranslation('core.common.addItemToUnitLoad')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_item_picker, {
            "exclude-item-ids": _ctx.excludedPartItemIdsForSearch,
            "ignore-combined-containers": "",
            "item-type": _ctx.ItemType.COMBINED,
            onOnSelect: _ctx.addItemToCombinedContainer
          }, null, 8, ["exclude-item-ids", "item-type", "onOnSelect"])
        ]),
        _: 1
      }, 8, ["modelValue", "title"])
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}