import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_form_input, _mergeProps(_ctx.$props, {
        "model-value": _ctx.value,
        "number-options": { allowsDecimal: true },
        required: _ctx.required,
        type: "number",
        "hide-stepper": "",
        onChange: _ctx.handleChange
      }), {
        appendGroupText: _withCtx(() => [
          _createVNode(_component_b_form_select, {
            modelValue: _ctx.uom.displayedUnitOfMeasure.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uom.displayedUnitOfMeasure.value) = $event)),
            options: _ctx.uomDropdownOptions,
            class: "uom-select",
            readonly: !_ctx.uom.hasMultipleOptions(),
            onChange: _ctx.onChangeMeasurement
          }, null, 8, ["modelValue", "options", "readonly", "onChange"])
        ]),
        _: 1
      }, 16, ["model-value", "required", "onChange"])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}