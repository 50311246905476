import Item from '@/domain/Item';
import { MetaData } from '@/meta-data/types';

export type ItemMetaData = Partial<Record<keyof Item, MetaData>>;

export const itemMetaData: ItemMetaData = {
    height: {
        uom: {
            type: 'length',
            measure: 'inches',
            spacial: 'linear',
        },
    },
    collapsedHeight: {
        uom: {
            type: 'length',
            measure: 'inches',
            spacial: 'linear',
        },
    },
    length: {
        uom: {
            type: 'length',
            measure: 'inches',
            spacial: 'linear',
        },
    },
    width: {
        uom: {
            type: 'length',
            measure: 'inches',
            spacial: 'linear',
        },
    },
    weight: {
        uom: {
            type: 'weight',
            measure: 'pounds',
        },
    },
};
