
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BFormUomInput from '@/components/bootstrap-library/BFormUomInput.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import Screen from '@/components/layout/Screen.vue';
import OrbisCard from '@/components/OrbisCard.vue';
import QuantityPicker from '@/components/QuantityPicker.vue';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import ItemTypeClass from '@/domain/ItemType';
import { useUnitOfMeasureForItem } from '@/measurement/useUnitOfMeasure';
import { itemMetaData } from '@/meta-data/item';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import { MasterDataStore } from '@/modules/master-data/store/MasterDataStore';
import ItemService from '@/services/ItemService';
import useValidator from '@/validation/useValidator';
import InventoryService from '@/services/InventoryService';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import UnitLoadPart from '@/domain/UnitLoadPart';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import ButtonUploadImage from '@/components/buttons/ButtonUploadImage.vue';
import { UploadImageEvent } from '@/domain/ImageUpload';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';

type State = {
    itemEdit: Item;
    key: number;
    loading: boolean;
    saving: boolean;
    showAddItemForCombinedContainer: boolean;
    hasQtyOnHand: boolean | null;
    newImageType: string;
};

type CombinedPartsTableData = {
    id: number;
    isPrimaryPlanningItem: boolean;
    name: string;
    imageUrlFull?: string;
    imageUrlThumb?: string;
    quantity: number;
};

export default defineComponent({
    name: 'master-data-item-edit',
    components: {
        SmartTrakFooter,
        BTable,
        BButton,
        BFormUomInput,
        BModal,
        BRow,
        BSpinner,
        CheckboxInput,
        ItemPicker,
        OrbisCard,
        QuantityPicker,
        Screen,
        SelectInput,
        TextInput,
        Thumbnail,
        ButtonUploadImage,
        BFormInput,
        BCol,
    },
    props: {
        itemId: {
            required: false,
            type: String,
            default: () => '',
        },
    },
    setup(props) {
        const itemService = new ItemService();
        const inventoryService = new InventoryService();
        const { itemTypes } = MasterDataStore.getInstance().configStore;
        const router = useRouter();
        const { titleCase } = useStringFormatter();

        const {
            useItemHeightUom, useItemLengthUom, useItemWidthUom, useItemWeightUom, useItemCollapsedHeightUom,
        } = useUnitOfMeasureForItem(itemMetaData);

        const { validateForm, validationResult } = useValidator<Item>('item');

        const state = reactive<State>({
            itemEdit: new Item(),
            key: 1,
            loading: false,
            saving: false,
            showAddItemForCombinedContainer: false,
            hasQtyOnHand: null,
            newImageType: '',
        });

        onBeforeMount(async () => {
            state.loading = true;

            if (props.itemId) {
                const itemId = parseInt(props.itemId, 10);
                const response = await itemService.getItemById(itemId);
                state.hasQtyOnHand = await inventoryService.getHasQtyOnHandByItemId(itemId);

                if (response.success) {
                    state.itemEdit = response.item;
                }
            } else {
                state.hasQtyOnHand = false;
            }

            state.loading = false;
        });

        const excludedPartItemIdsForSearch = computed((): number[] => [state.itemEdit.id, ...state.itemEdit.unitLoadParts.map((part) => part.partId)]);
        const pageTitle = computed(() => (state.itemEdit.id ? getTitleCaseTranslation('core.domain.editItem') : getTitleCaseTranslation('core.button.addItem')));
        const canEditCombinedBuild = computed((): boolean => state.hasQtyOnHand === false);

        function addItemToCombinedContainer(evtItemWithQty: { item: Item | null; quantity: number }) {
            if (evtItemWithQty.item) {
                state.itemEdit.addPartToUnitLoad(evtItemWithQty.item, evtItemWithQty.quantity);
            }

            state.showAddItemForCombinedContainer = false;
        }

        function displayAddItemModal() {
            state.showAddItemForCombinedContainer = true;
        }

        function goToItemList() {
            router.push({ name: MasterDataRouteTypes.ITEM.LIST });
        }

        function removePartFromCombinedItem(partId: number) {
            state.itemEdit.removePartFromUnitLoad(partId);
        }

        async function save() {
            validateForm(state.itemEdit);

            if (validationResult.isValid) {
                state.saving = true;

                const response = await (state.itemEdit.id > 0 ? itemService.updateItem(state.itemEdit) : itemService.addNewItem(state.itemEdit));
                if (response) {
                    goToItemList();
                }

                state.saving = false;
            }
        }

        function setItemType(itemType: ItemTypeClass) {
            state.itemEdit.type = itemType;
        }

        function setPrimaryPlanningPartForUnitLoad(partId: number, newValue: boolean) {
            state.itemEdit.setPrimaryPlanningPartForUnitLoad(partId, newValue);
        }

        const combinedTableFields = computed(
            (): Array<BTableField<CombinedPartsTableData & { action?: undefined }>> => {
                const fields: Array<BTableField<CombinedPartsTableData & { action?: undefined }>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.domain.image'),
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'quantity',
                        label: getTitleCaseTranslation('core.domain.quantity'),
                    },
                    {
                        key: 'isPrimaryPlanningItem',
                        label: getTitleCaseTranslation('core.domain.primaryPlanningItem'),
                    },
                ];
                if (canEditCombinedBuild.value) fields.push({ key: 'action', label: ' ' });
                return fields;
            },
        );

        function mapCombinedPartsToTableData(parts: Array<UnitLoadPart>): Array<CombinedPartsTableData> {
            return parts.map((data) => ({
                id: data.part.id,
                isPrimaryPlanningItem: data.isPrimaryPlanningItem,
                imageUrlFull: data.part.imageUrlFull,
                imageUrlThumb: data.part.imageUrlThumb,
                name: data.part.name,
                quantity: data.quantity,
            }));
        }

        const combinedTableData = computed((): Array<CombinedPartsTableData> => mapCombinedPartsToTableData(state.itemEdit.unitLoadParts));

        function changeCombinedPartQty(qty: number, id: number) {
            state.itemEdit.unitLoadParts.forEach((part) => {
                if (part.part.id === id) {
                    part.quantity = qty;
                }
            });
        }

        async function onUploadImage(value: UploadImageEvent) {
            if (value.base64String) {
                state.itemEdit.image = value.base64String;
                state.newImageType = value.type;
            }
        }

        return {
            addItemToCombinedContainer,
            displayAddItemModal,
            excludedPartItemIdsForSearch,
            goToItemList,
            ItemType,
            itemTypes,
            pageTitle,
            removePartFromCombinedItem,
            save,
            setItemType,
            state,
            useItemCollapsedHeightUom,
            useItemHeightUom,
            useItemLengthUom,
            useItemWeightUom,
            useItemWidthUom,
            validationResult,
            canEditCombinedBuild,
            combinedTableFields,
            combinedTableData,
            changeCombinedPartQty,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            onUploadImage,
            setPrimaryPlanningPartForUnitLoad,
        };
    },
});
