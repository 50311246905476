
import {
    computed, defineComponent, nextTick, PropType, ref,
} from 'vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import { UseUnitOfMeasure } from '@/measurement/types';
import { getTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'b-form-uom-input',
    components: { BFormInput, BFormSelect },
    extends: BFormInput,
    props: {
        cols: { type: String, default: undefined },
        lg: { type: String, default: undefined },
        md: { type: String, default: undefined },
        sm: { type: String, default: undefined },
        xs: { type: String, default: undefined },
        modelValue: { type: Number, required: true },
        uom: { type: Object as PropType<UseUnitOfMeasure>, required: true },
        required: { type: Boolean, default: false },
    },
    emits: ['change', 'update:modelValue'],
    setup(props, context) {
        const value = ref(props.uom.convertFromBase(props.modelValue));

        const uomDropdownOptions = computed(() => props.uom.measureOptions.value.map((option) => ({
            text: getTranslation(`core.uom.${option}`),
            value: option,
        })));

        function handleChange(val: number) {
            value.value = val;
            context.emit('change', props.uom.convertToBase(val));
            context.emit('update:modelValue', props.uom.convertToBase(val));
        }

        function onChangeMeasurement() {
            nextTick(() => {
                value.value = props.uom.convertFromBase(props.modelValue);
            });
        }

        return {
            handleChange,
            value,
            onChangeMeasurement,
            uomDropdownOptions,
        };
    },
});
